<template>
    <div class="line-detail">
        <van-row class="head">
            <van-col span="8">流向</van-col>
            <van-col span="8">货量（万吨）</van-col>
            <van-col span="8">营收（万元）</van-col>
        </van-row>
      <div v-if="data.length>0">
        <div v-for="(item,index) in data" v-bind:key="index">
            <van-row>
                <van-col class="body" span="8">{{item.cfDTO.route}}</van-col>
                <van-col class="body" span="8">{{item.cfDTO.weight}}</van-col>
                <van-col class="body" span="8">{{item.cfDTO.profit}}</van-col>
            </van-row>
        </div>
      </div>
        <div v-else>
          <van-empty image="error" description="暂无数据"/>
        </div>
    </div>
</template>

<script>
    import {Toast} from "vant";
    export default {
        name: "LineDetail",
        components: {Toast},
        mounted() {
          this.init()
        },
        methods:{
            init() {
              Toast.loading({
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner'
              });
              //获取航线明细
              this.$ajax.post('/api/ajax/bulkCrmsCusList/getHxmx.json',
                  {
                    customerName: this.$route.params.name
                  }).then(res => {
                if (res.status == 200) {
                  if(res.data.length>0){
                    this.data = res.data
                    console.log(this.data)
                  }
                }
                Toast.success('加载成功');
              }).catch(err => {
                Toast.fail('加载失败');
                console.log(err)
                this.$message.error('获取客户航线明细失败！');
              })
            }
        },
        data() {
            return {
                data: [

                ]
            }
        }
    }
</script>

<style scoped>
    .head {
        background: #318EF5;
    }

    .body {
        border-width: 1px 0 0 1px;
        border-color: #318EF5;
        border-style: solid;
    }

    .line-detail {
        padding: 5px;
        height:  850px;
        background: white;
    }
</style>
